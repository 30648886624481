import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    effect,
    inject,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { Observable, takeWhile, tap } from 'rxjs';
import { LetDirective } from '@ngrx/component';
import { getRouterSelectors } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { NgForOf, NgIf } from '@angular/common';

import { MobiUiIconComponent, MobiUiIconsRegistry } from '@mobi/rwc-ui-components-ng-jslib';
import { mobiIconLeft } from '@mobi/rwc-utils-icons-jslib';
import { TealiumTrackButtonDirective } from '@mobi/tea-tagmanager-ng-jslib';
import { Content, OivCoreSharedFacade, TealiumTrackingFacade, URL_PATH } from '@mobi/oiv-viewer-utils-ng-jslib';
import { OIV_TYPE } from '@mobi/oiv-viewer-xml-parser-ng-jslib';

import { HeadingComponent } from '../../../share/components/heading/heading.component';
import { ContentItem, NavigationItem } from '../../../share/model/atomic-model';
import { ROUTE_PATHS } from '../../../util/constant/route-paths';
import { filterDefined } from '../../../util/filter-defined';
import { getParagraphCss } from '../../../util/get-css-style';
import { ChapterTopicsPageComponentStore } from '../data/component-store/chapter-topics-page.component-store.service';
import { NavigationIndexComponent } from '../../../share/components/navigation-index/navigation-index.component';
import { Helper } from '../../../share/helper';
import { ParagraphComponent } from '../../../share/components/paragraph/paragraph.component';
import { OivViewerFacade } from '../../core/data/facade/oiv-viewer-facade';

@Component({
    standalone: true,
    selector: 'oiv-viewer-chapter-topic-page',
    templateUrl: './chapter-topics-page.component.html',
    styleUrls: ['./chapter-topics-page.component.scss'],
    imports: [
        HeadingComponent,
        LetDirective,
        MobiUiIconComponent,
        NavigationIndexComponent,
        TealiumTrackButtonDirective,
        ParagraphComponent,
        NgForOf,
        NgIf,
    ],
    providers: [ChapterTopicsPageComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChapterTopicsPageComponent implements OnInit {
    #router = inject(Router);
    readonly helper = inject(Helper);

    @ViewChild('container', { read: ViewContainerRef, static: true }) ref!: ViewContainerRef;

    #cdr = inject(ChangeDetectorRef);
    #store = inject(Store);
    #destroyRef = inject(DestroyRef);
    #componentStore = inject(ChapterTopicsPageComponentStore);
    #coreSharedFacade = inject(OivCoreSharedFacade);
    #oivViewerFacade = inject(OivViewerFacade);
    #tealiumTrackingFacade = inject(TealiumTrackingFacade);

    heading$: Observable<ContentItem> = new Observable<ContentItem>();
    navigationItems$: Observable<NavigationItem[]> = new Observable<NavigationItem[]>();
    readonly homePage = this.helper.getHomePageURL(
        this.#router,
        this.#coreSharedFacade.applicationType(),
        this.#coreSharedFacade.isApprovalMode(),
    );
    #currentUrl = this.#store.selectSignal(getRouterSelectors().selectUrl);
    #language = this.#coreSharedFacade.currentLanguage;
    #latestUrl = '';
    #chapterId = '';

    constructor() {
        inject(MobiUiIconsRegistry).registerIcons([mobiIconLeft]);

        effect(
            () => {
                // Load the chapter topics page when the language changes
                if (this.#language()) {
                    this.loadChapterTopicsPageContent(this.#chapterId);
                }
            },
            { allowSignalWrites: true },
        );
    }

    ngOnInit(): void {
        this.#store
            .select(getRouterSelectors().selectRouteParam(ROUTE_PATHS.ROUTE_PARAM_CHAPTER_ID))
            .pipe(
                takeUntilDestroyed(this.#destroyRef),
                takeWhile(() => this.#latestUrl === '' || this.#latestUrl !== this.#currentUrl()),
                tap(() => (this.#latestUrl = this.#currentUrl())),
                filterDefined,
            )
            .subscribe(chapterId => {
                this.#chapterId = chapterId;
                this.loadChapterTopicsPageContent(chapterId);
            });
    }

    loadChapterTopicsPageContent(chapterId: string) {
        const chapterContent = this.#oivViewerFacade.getSelectedChapter(chapterId)() as Content;
        this.#componentStore.setSelectedChapter(chapterContent);
        this.#tealiumTrackingFacade.trackPageView(chapterContent, OIV_TYPE.CHAPTER_TITLE);
        this.heading$ = this.#componentStore.getHeading;
        this.navigationItems$ = this.#componentStore.mapTopicsToNavigationItems;
        this.#render(chapterContent);
    }

    #render(chapterContent: Content) {
        this.ref.clear();
        this.helper
            .getContentAsArray(chapterContent)
            .filter(content => content.type !== OIV_TYPE.TOPIC)
            .forEach(content => this.#getComponent(content));
        this.#cdr.detectChanges();
    }

    #getComponent(content: Content) {
        switch (content.type) {
            case OIV_TYPE.INFO:
            case OIV_TYPE.INTRO:
            case OIV_TYPE.PARA: {
                const component = this.ref.createComponent(ParagraphComponent);
                component.setInput('content', content.content);
                component.setInput('eId', content.eId);
                component.setInput('cssClass', getParagraphCss(content.type));
                return component;
            }
            default:
                return;
        }
    }

    navigateTo(navigationItem: NavigationItem): void {
        // Get the current URL and remove any fragment identifiers before navigating to the new URL
        const currentUrl = this.#router.url.split('#')[0].replace(`/${URL_PATH.TOPICS}`, '');
        const newUrl = `${currentUrl}/${navigationItem.urlLink}`;
        this.#router.navigateByUrl(newUrl);
    }

    goBack(): void {
        this.#router.navigate([this.homePage]);
    }
}
