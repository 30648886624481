<div class="mobi-ui-centered-vertical mobi-ui-gap-lg">
    <button
        type="button"
        class="mobi-ui-btn-icon-minor mobi-ui-ink-grey oiv-back-button mobi-ui-margin-b-sm"
        mobiTealiumTrackButton="chapter-topic-back"
        [trackingButtonTarget]="homePage"
        (click)="goBack()"
    >
        <mobi-ui-icon-major name="left" [isInked]="true"></mobi-ui-icon-major>
    </button>
    <ng-container *ngrxLet="heading$ as heading">
        <oiv-viewer-heading [heading]="heading"></oiv-viewer-heading>
    </ng-container>
</div>
<div class="mobi-ui-margin-y-sm">
    <ng-template #container></ng-template>
</div>
<div *ngrxLet="navigationItems$ as navigationItems">
    <oiv-viewer-navigation-index [navigationItems]="navigationItems" (navigationClick)="navigateTo($event)" />
</div>
